.arrow {
  animation: arrow 1s infinite;
}

@keyframes arrow {
  0%,
  100% {
    transform: translateX(-10%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(20%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
