.church {
  color: transparent;
  background-image: linear-gradient(to bottom left, #a1ef53, #54ccf8, #6d9c5a, #2fe89e, #8cd746);
  background-clip: text;
  -webkit-background-clip: text;
  animation: animate 7s linear infinite;
  background-size: 500%;
  -webkit-background-size: 500%;
}

@keyframes animate {
  0% {
      background-position: 0 100%;
  }
  50% {
      background-position: 100% 0;
  }
  100% {
      background-position: 0 100%;
  }
}